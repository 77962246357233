<template lang="pug">
footer
  .footer-inner
    .footer-category
      .footer-category-content
        .footer-category-content-ttl
          | ヘルプ・お問い合わせ
        ul
          li
            a.external_link#footer_ads(href="https://inquiry.vook.co.jp/corporate" target="_blank")
              | 広告掲載について
          li
            nuxt-link.external_link#footer_contact(to="/inquiry/new")
              | お問い合わせ
          li
            nuxt-link.external_link#footer_support(to="/support")
              | よくある質問
      .footer-category-content
        .footer-category-content-ttl
          | 利用規約
        ul
          li
            nuxt-link.external_link#footer_terms(to="/pages/terms")
              | 利用規約
          li
            nuxt-link.external_link#footer_privacy(to="/pages/privacy")
              | プライバシーポリシー
          li
            nuxt-link.external_link#footer_law(to="/pages/law")
              | 特定商取引法の表示
      .footer-category-content
        .footer-category-content-ttl
          | 他サービス
        ul
          li
            a.external_link#footer_career(:href="$urls.career" target="_blank")
              | Vookキャリア
          li
            a.external_link#footer_school(:href="$urls.school" target="_blank")
              | Vook school
      .footer-category-content
        .footer-category-content-ttl
          | 運営会社
        ul
          li
            a.external_link#footer_corporate(href="https://vook.co.jp/" target="_blank")
              | 会社概要
          //- MEMO: 更新ができていないので一旦落とす。いつか復活予定
          //- li
            a.external_link#footer_recruit(href="https://www.notion.so/b9abe2bdf4f14dc39a8b5077ce1be29d" target="_blank")
              | 採用情報
    .flex
      .footer-logo
        nuxt-link.external_link#footer_logo(to="/")
          icon-logo-vook-svg
        address
          | &copy;
          | {{ nowYear() }}
          | &nbsp;Vook
          | .
      .footer-sns
        ul
          li
            a.external_link#footer_line(href="https://lin.ee/Jg59z11" target="_blank")
              img(v-lazy="require('~/assets/images/common/line.png')" width="30" height="30")
          li
            a.external_link#footer_tiktok(href="https://www.tiktok.com/@vook_media" target="_blank")
              img(v-lazy="require('~/assets/images/common/tiktok-b.png')" width="30" height="30")
          li
            a.external_link#footer_instagram(href="https://www.instagram.com/vook_magazine/" target="_blank")
              img(v-lazy="require('~/assets/images/common/instagram.png')" width="30" height="30")
          li
            a.external_link#footer_twitter(href="https://twitter.com/Vookjp" target="_blank")
              sns-twitter-svg
          li
            a.external_link#footer_youtube(href="https://www.youtube.com/channel/UCwQ0VT8WWOT5Nvcg5Q43zxA" target="_blank")
              img(v-lazy="require('~/assets/images/common/youtube.png')" width="30" height="30")
          li
            a.external_link#footer_facebook(href="https://www.facebook.com/VookJp/" target="_blank")
              img(v-lazy="require('~/assets/images/common/facebook.png')" width="30" height="30")
</template>

<script>
import IconLogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'
import SnsTwitterSvg from '~/assets/images/svg/sns_twitter.svg?inline'
export default {
  components: {
    IconLogoVookSvg,
    SnsTwitterSvg
  },
  methods: {
    nowYear() {
      const now = new Date()
      return now.getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
footer {
  background: $font_color_base;
  color: #fff;
  @include media(pc) {
    padding: 55px 0 5px;
  }
  @include media(sp) {
    padding-top: $spacer_1;
  }
  .footer-inner {
    max-width: $min_width;
    margin: 0 auto;
  }
  .footer-category {
    border-bottom: 1px solid #4e4e4e;
    @include media(pc) {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    &-content {
      @include media(pc) {
        flex: 1;
      }
      @include media(sp) {
        padding: $spacer_4 $spacer_2 $spacer_3;
      }
      &-ttl {
        font-size: 16px;
        font-weight: bold;
        padding: 0 $spacer_1;
        margin-bottom: 15px;
        @include media(sp) {
          margin-bottom: 10px;
        }
      }
      ul {
        li {
          a {
            display: inline-block;
            padding: $spacer_1;
            text-decoration: none;
            font-size: 14px;
            color: #fff;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        li + li {
          margin-top: 3px;
        }
      }
      & + .footer-category-content {
        @include media(sp) {
          border-top: 1px solid #4e4e4e;
        }
      }
    }
  }
  .flex {
    display: flex;
    @include media(pc) {
      justify-content: space-between;
      align-items: center;
      padding: 30px 5px;
    }
    @include media(sp) {
      flex-direction: column-reverse;
    }
  }
  .footer-logo {
    display: flex;
    @include media(pc) {
      align-items: flex-end;
    }
    @include media(sp) {
      align-items: center;
      padding: $edge_padding;
    }
    a {
      display: block;
      svg {
        fill: #fff;
        @include media(pc) {
          width: 80px;
          height: 28px;
        }
        @include media(sp) {
          width: 58px;
          height: 20px;
        }
      }
    }
    address {
      font-style: unset;
      margin-left: 20px;
    }
  }
  .footer-sns {
    @include media(sp) {
      padding: $edge_padding;
      border-bottom: 1px solid #4e4e4e;
    }
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li + li {
        @include media(pc) {
          margin-left: 25px;
        }
        @include media(sp) {
          margin-left: 15px;
        }
      }
      // 389px以下のときは、アイコンを5つのみ表示する（LINEアイコンがモバイル時40px以上必須のため、横並びにできる限界が5つのため）
      @media screen and (max-width: 389px) {
        li:nth-child(n + 6) {
          display: none;
        }
      }
      a {
        display: block;
        &:hover {
          opacity: 0.3;
        }
      }
      img,
      svg {
        // MEMO: LINEアイコンがPC20px以上、モバイル40px以上必要
        @include media(pc) {
          width: 30px;
          height: auto;
        }
        @include media(sp) {
          width: 40px;
          height: auto;
        }
      }
      svg {
        fill: #fff;
      }
    }
  }
}
</style>
