import { Middleware } from '@nuxt/types'
import { beforeIt } from '~/libs/dayjs'

const middleware: Middleware = ({ $auth, redirect, params }) => {
  const schoolCampIds: any = $auth.user!.school_camp_ids || []
  if (!schoolCampIds.includes(Number(params.camp_id))) {
    redirect('/')
  }
  if (Number(params.camp_id) === 2 && !beforeIt('2022-11-07 23:59:59')) {
    redirect('/')
  }
  if (Number(params.camp_id) === 3 && !beforeIt('2023-11-01 23:59:59')) {
    redirect('/')
  }
  if (Number(params.camp_id) === 4 && !beforeIt('2024-12-18 23:59:59')) {
    redirect('/')
  }
}

export default middleware
